import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { AppSettingsClientAdapter } from '@wix/bookings-adapter-ooi-wix-sdk/dist/src/AppSettingsClientAdapter';
import {
  mergeAppSettingsToSettingsParams,
  mergeAppSettingsToStyleParams,
  ServiceListSettings,
} from './appSettings';
import { appClient, Scope } from '@wix/app-settings-client';
import {
  SINGLE_SERVICE_EDITOR_X_PRESET_ID,
  SINGLE_SERVICE_PRESET_ID,
} from '../appKeys/DefaultSettings';
import { cleanNulls, mergeOpacityToColor } from '../utils';
import { FilterType, SettingsKeys, WidgetData } from '../types';
import { filterResources } from '../../src/utils/filters/services-filter';
import { createWidgetViewModel } from '../../src/viewModel/viewModel';
import { Resources, ViewMode } from '../../src/types/types';
import { AppSettingsColor } from './mergeColors';
import { getCourseAvailability } from '../../src/actions/courseAvailability/courseAvailability';
import { isRunningInIframe } from '@wix/bookings-catalog-calendar-viewer-utils';
import { ActiveFeatures } from '@wix/bookings-uou-types';

export const getAppSettings = async (
  flowAPI: ControllerFlowAPI,
) => {
  const { controllerConfig } = flowAPI;
  const { config, appParams } = controllerConfig;
  let appSettings;

  if (flowAPI.environment.isEditor && isRunningInIframe()) {
    appSettings = appClient({ scope: Scope.COMPONENT });
  } else {
    appSettings = appClient({
      scope: Scope.COMPONENT,
      adapter: new AppSettingsClientAdapter({
        appDefId: appParams.appDefinitionId,
        instanceId: appParams.instanceId,
        externalId: config.externalId || '',
      }),
    });
  }
  return appSettings;
};

export const getUserSettings = async (
  appSettings: any,
  presetId: string,
  userData: WidgetData,
) => {
  const getAllSettings = async () => {
    try {
      return await appSettings.getAll();
    } catch (e) {
      return null;
    }
  };
  let userSettings: ServiceListSettings =
    userData.config.settings || (await getAllSettings());
  userSettings = cleanNulls(userSettings);
  if (
    userSettings &&
    (presetId === SINGLE_SERVICE_PRESET_ID ||
      presetId === SINGLE_SERVICE_EDITOR_X_PRESET_ID) &&
    !userSettings.SELECTED_RESOURCES
  ) {
    userSettings.SELECTED_RESOURCES = {
      categories: [],
      offerings: [],
      filter: FilterType.FIRST,
    };
  }
  return userSettings;
};

export const updatePublicData = async ({
  filteredResources,
  userData,
  dangerousPublicDataOverride,
  dangerousStylesOverride,
  newUserStylesSettings,
  newUserSettings,
  presetId,
  scale,
  viewMode,
  flowAPI,
  shouldWorkWithAppSettings,
}: {
  filteredResources: Resources;
  newUserSettings: ServiceListSettings;
  userData: WidgetData;
  presetId: string;
  flowAPI: ControllerFlowAPI;
  dangerousStylesOverride: Function;
  dangerousPublicDataOverride: Function;
  scale: number;
  viewMode: ViewMode;
  newUserStylesSettings?: any;
  shouldWorkWithAppSettings: boolean;
}) => {
  const { controllerConfig } = flowAPI;
  const { setProps, config } = controllerConfig;
  newUserSettings = cleanNulls(newUserSettings);
  const userStylesColorsWithOpacity = {};
  if (newUserStylesSettings) {
    (Object.keys(newUserStylesSettings.colors || {}) as SettingsKeys[]).forEach(
      (colorKey) => {
        // @ts-expect-error
        userStylesColorsWithOpacity[colorKey] = {
          ...newUserStylesSettings.colors[colorKey],
          value: newUserSettings[colorKey]
            ? mergeOpacityToColor(
                (newUserSettings[colorKey] as AppSettingsColor).value,
                newUserStylesSettings.colors[colorKey].value,
              )
            : newUserStylesSettings.colors[colorKey].value,
        };
      },
    );
  }
  const stylesProp = dangerousStylesOverride(
    mergeAppSettingsToStyleParams(
      {
        ...newUserSettings,
        ...userStylesColorsWithOpacity,
        ...(newUserStylesSettings ? newUserStylesSettings.fonts : {}),
      },
      {
        booleans: {},
        numbers: {},
        googleFontsCssUrl: '',
      },
      presetId,
    ),
  );
  const publicData = dangerousPublicDataOverride(
    mergeAppSettingsToSettingsParams(
      newUserSettings,
      config.publicData,
      presetId,
    ),
  );

  if (userData.offerings && userData.offerings.length) {
    filteredResources = filterResources(
      userData.offerings,
      userData.categories,
      userData.locations,
      newUserSettings,
    );
  }
  const widgetViewModel = await createWidgetViewModel({
    scale,
    businessInfo: userData.config.businessInfo,
    filteredResources,
    flowAPI,
    viewMode,
    shouldWorkWithAppSettings,
    activeFeatures: {} as ActiveFeatures,
  });
  widgetViewModel.coursesAvailability = await getCourseAvailability(
    flowAPI,
    filteredResources,
  );
  setProps({
    widgetViewModel,
    ...stylesProp,
    ...publicData,
  });
};
